import PropTypes from 'prop-types';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Card, Grid, Typography, CardContent, Backdrop } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { NavLink } from 'react-router-dom';
import { useState, useRef } from 'react';
// utils
import { fDate } from '../../../utils/formatTime';


// ----------------------------------------------------------------------

const StyledCardMedia = styled('div')({
  position: 'relative',
  paddingTop: 'calc(100% * 3 / 4)',
});

const StyledTitle = styled(Typography)({
  height: 44,
  overflow: 'hidden',
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
});

const StyledCover = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

const StyledVideo = styled('video')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
}); 

// ----------------------------------------------------------------------

BlogPostCard.propTypes = {
  post: PropTypes.object.isRequired,
  index: PropTypes.number,
  cover: PropTypes.string,
  isPost: PropTypes.bool
};

export default function BlogPostCard({ post, index, cover: sourceUrl, isPost }) {
  const videoPlayer = useRef();

  let title = (isPost) ? '' : post.folder_name;
  const createdAt = (isPost) ? '' : fDate(post.last_modified_date);
  const latestPostLarge = index === 0;
  const latestPost = index >= 1;
  const navigationLink = `/${post.folder_name}/?date=${createdAt}`;

  const [isImageOpen, setImageOpen] = useState(false);

  const isImage = !sourceUrl.endsWith('.mp4');
  const isVideo = sourceUrl.endsWith('.mp4');
  if (!isImage) { // Show play button for video
    title = <PlayCircleOutlineIcon fontSize='large'/>;
  }

  // Only allow the image to be opened when window width >= 600 or always open when it is a video.
  const handleImageOpen = () => {
    if (window.innerWidth >= 600 || isVideo) {
      setImageOpen(true);
    }
  }

  const imageAltText = `${post.folder_name} onlyfans leaks, leak, leaked, nude, girl, pretty woman`;

  return (
    <Grid item xs={12} sm={latestPostLarge ? 12 : 6} md={latestPostLarge ? 6 : 3}>
      {/* POP UP view */}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isImageOpen}
        onClick={() => {if (isImage) { setImageOpen(false) }}} // Click anywhere to close only when it is an image
      >
        { (isImage) 
          ? <StyledCover
              sx={{
                p: '20px',
                width: '90%',
                objectFit: 'contain',
                position: 'absolute',
              }}
              src={sourceUrl}
              alt={imageAltText}
            />
          // eslint-disable-next-line jsx-a11y/media-has-caption
          : <StyledVideo ref={videoPlayer} sx={{ top: '10%', width: '60%', height: 650, objectFit: 'contain' }} controls>
              <source src={sourceUrl} type="video/mp4"/>
              Your browser does not support the video tag.
          </StyledVideo>
        }

        <CloseIcon 
          fontSize='large' 
          sx={{ position: 'fixed', top: 20, right: 20, cursor: 'pointer'}}
          onClick={() => { 
            setImageOpen(false);
            videoPlayer.current.pause();
          }} // Option to close always available
        />

      </Backdrop>
      <Card sx={{ position: 'relative' }}>
        <NavLink to={navigationLink} onClick={handleImageOpen}>
          <StyledCardMedia
            sx={{
              ...((latestPostLarge || latestPost) && {
                pt: 'calc(100% * 4 / 3)',
                '&:after': {
                  top: 0,
                  content: "''",
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  bgcolor: (theme) => {
                    if (isPost) {
                      return ''
                    }
                    return alpha(theme.palette.grey[900], 0.4)
                  }
                },
              }),
              ...(latestPostLarge && {
                pt: {
                  xs: 'calc(100% * 4 / 3)',
                  sm: 'calc(100% * 3 / 4.66)',
                },
              }),
            }}
          >
            { (isImage) 
              ? <StyledCover src={sourceUrl} alt={imageAltText} />
              // eslint-disable-next-line jsx-a11y/media-has-caption
              : <StyledVideo>
                  <source src={sourceUrl} type="video/mp4" />
                  Your browser does not support the video tag.
                </StyledVideo>
            }
            
          </StyledCardMedia>
          <CardContent
            // hidden={isPost}
            sx={{
              pt: 4,
              ...((latestPostLarge || latestPost) && {
                bottom: 0,
                width: '100%',
                position: 'absolute',
              }),
            }}
          >
            <Typography variant="caption" sx={{ color: 'text.disabled', display: 'block' }}>
              {createdAt}
            </Typography>

            <StyledTitle
              color="inherit"
              variant="subtitle1"
              sx={{
                ...(latestPostLarge && { typography: 'h4', height: 60 }),
                ...((latestPostLarge || latestPost) && {
                  color: 'common.white',
                }),
              }}
            >
              {title}
            </StyledTitle>
          </CardContent>
        </NavLink>
      </Card>
    </Grid>
  );
}
