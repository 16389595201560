import PropTypes from 'prop-types';

import { useState } from 'react';

// @mui
import { Autocomplete, InputAdornment, Chip, TextField, Stack } from '@mui/material';

// components
import Iconify from '../../../components/iconify';


// ----------------------------------------------------------------------

BlogPostsSearch.propTypes = {
  posts: PropTypes.array.isRequired,
  onSearch: PropTypes.func,
  onSort: PropTypes.func,
  postsCount: PropTypes.number
};

const SORT_OPTIONS = ["Latest", "A-Z", "Shuffle"];

export default function BlogPostsSearch({ posts, onSearch, onSort, postsCount }) {
  const [sortOption, setSortOption] = useState(SORT_OPTIONS[2]);

  const sortedPosts = posts.sort((a, b) => {
    const nameA = a.folder_name.toUpperCase();
    const nameB = b.folder_name.toUpperCase(); 
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  });

  const handleSort = (label) => {
    setSortOption(label);
    onSort(label);
  }

  const sortOptionChip = (label) => <Chip variant={(sortOption === label) ? "" : "outlined"} label={label} onClick={() => handleSort(label)} clickable />

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Autocomplete
        freeSolo
        options={sortedPosts}
        getOptionLabel={(post) => post.folder_name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onInputChange={(event, newInputValue) => {
          onSearch(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={`Filter ${postsCount} collections`}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon={'eva:search-fill'} sx={{ ml: 1, width: 20, height: 20, color: 'text.disabled' }} />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
      <Stack direction="row" spacing={1}>
        { SORT_OPTIONS.map((option) => sortOptionChip(option)) }
      </Stack>
    </Stack>
    
  );
}
