
// Disable cache for now since CDN also caches it
// eslint-disable-next-line
export const getCachedData = async (url, cacheName) => {
    return false;
    // Disable cache for now since CDN also caches it
    // if (typeof caches === 'undefined') return false;

    // const cacheId = cacheName || 'loif.club';
    // const cacheStorage = await caches.open(cacheId);
    // const cachedResponse = await cacheStorage.match(url);

    // if (!cachedResponse || !cachedResponse.ok) {
    //     return false;
    // }

    // return cachedResponse.json();
}

// Disable cache for now since CDN also caches it
// eslint-disable-next-line
export const putCacheData = (url, data, cacheName) => {
    // const cacheId = cacheName || 'loif.club';
    // const response = new Response(JSON.stringify(data));

    // if ('caches' in window) {
    //     caches.open(cacheId).then(
    //         (cache) => {
    //             cache.put(url, response);
    //         }
    //     )
    // }
}

export const deleteCache = (cacheName) => {
    const cacheId = cacheName || 'xkeycode';
    caches.delete(cacheId);
}