import { useEffect } from 'react';
import { PropTypes } from 'prop-types';

JuicyAdsComponent.propTypes = {
    adType: PropTypes.string,
}
export default function JuicyAdsComponent({ adType = null }) {
    const isMobile = window.innerWidth < 600; // Phone screen size

    useEffect(() => {
        console.log(window.adsbyjuicy);
        // (window.adsbyjuicy = window.adsbyjuicy || []).push(
        //     { 'adzone': 1038838 }, 
        //     { 'adzone': 1039697 },
        //     { 'adzone': 1040137 });
    }, [])

    const mobileAd = (
        <ins id="1039697" data-width="300" data-height="112" />
    );

    const bannerAd = (
        <ins id="1038838" data-width="728" data-height="102" />
    )

    const squareAd = (
        <ins id="1040137" data-width="300" data-height="262" />
    )

    const getAd = () => {
        switch (adType) {
            case "mobile":
                return mobileAd;
            case "banner":
                return bannerAd;
            case "square":
                return squareAd;
            default:
                return (isMobile) 
                    ? mobileAd
                    : bannerAd
        }
    }
    

    return (
        <>
            { getAd() }
        </>
    );
}