import { Helmet } from 'react-helmet-async';
// @mui
import { Grid, Button, Container, Stack, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import { GET_MODELS_URL } from '../utils/services';

// components
import { BlogPostCard, BlogPostsSearch } from '../sections/@dashboard/blog';
import JuicyAdsComponent from '../components/ads/JuicyAdsComponent';

// ----------------------------------------------------------------------

export default function LoifPage() {
  const DEFAULT_MODELS_SHOWN = 39;
  const [initialNames, setInitialNames] = useState([]);
  const [modelNames, setModelNames] = useState([]);
  const [indexShowing, setIndexShowing] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetch(GET_MODELS_URL)
        .then(res => res.json())
        .then(
          (result) => {
            if (result !== undefined) {
              console.debug(result);
              setModelNames(result);
              setInitialNames(result);
            }
            
          },
          (error) => {
            console.error(error);
          }
        );

  }, []);

  const handleNext = () => {
    const calc = indexShowing + DEFAULT_MODELS_SHOWN;
    if (calc < modelNames.length) {
      setIndexShowing(calc);
      setCurrentPage(currentPage + 1);
      window.scrollTo(0, 0);
    }
  }

  const handlePrevious = () => {
    const calc = indexShowing - DEFAULT_MODELS_SHOWN;
    if (calc >= 0) {
      setIndexShowing(calc);
      setCurrentPage(currentPage - 1);
      window.scrollTo(0, 0);
    }
  }

  const handleSearch = (filterValue) => {
    if (filterValue === '') { // Reset to inital names
      setModelNames(initialNames);
    } else {
      const filteredNames = initialNames.filter((m) => m.folder_name.includes(filterValue));
      console.log(filteredNames)
      setModelNames(filteredNames);
    }
    setCurrentPage(1);
    setIndexShowing(0);
  }

  // Sort the models
  const handleSort = (sortOption) => {
    const names = [...initialNames]; // clone
    switch(sortOption) {
      case "Hottest":
        break;

      case "Shuffle":
        setModelNames(names.sort(() => Math.random() - 0.5));
        break;

      case "A-Z":
        names.sort((a, b) => {
          const nameA = a.folder_name.toUpperCase();
          const nameB = b.folder_name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          return 0;
        })
        setModelNames(names);

        break;
      default:  // Latest
        setModelNames(initialNames);
        break;
    }
  }

  return (
    <>
      <Helmet>
        <title> Lòi | Home </title>
      </Helmet>

      <Container sx={{ paddingBottom: '20px' }}>
        <Stack mb={5} direction="row" alignItems="center" justifyContent="space-between">
          <BlogPostsSearch posts={[...modelNames]} onSearch={handleSearch} onSort={handleSort} postsCount={initialNames.length}/>
        </Stack>

        <JuicyAdsComponent />

        <Grid container spacing={3}>
          {modelNames.slice(indexShowing, Math.min(indexShowing + DEFAULT_MODELS_SHOWN, modelNames.length)).map((model, index) => (
            <BlogPostCard 
              key={index} 
              post={model} 
              index={index} 
              cover={`https://loif-xxx.s3.amazonaws.com/models/${model.folder_name}/${model.cover}`}
            />
          ))}
        </Grid>

        <Stack direction="row" alignItems="center" justifyContent="center" spacing={3} m={3}>
          <Button variant="outlined" startIcon={<NavigateBeforeIcon size="large"/>} onClick={handlePrevious} />
          <Typography variant="h6"> { currentPage } / { Math.ceil(modelNames.length / DEFAULT_MODELS_SHOWN) } </Typography>
          <Button variant="outlined" endIcon={<NavigateNextIcon size="large"/>} onClick={handleNext} />
        </Stack>

        <JuicyAdsComponent />

      </Container>
    </>
  );
}
