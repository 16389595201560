import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
// @mui
import { Grid, Button, Container, Stack, Typography, Backdrop, CircularProgress } from '@mui/material';
import { useState, useEffect } from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import { GET_MODEL_IMAGES_URL } from '../utils/services';

// components
import { BlogPostCard } from '../sections/@dashboard/blog';
import { getCachedData, putCacheData } from '../utils/cacher';
import JuicyAdsComponent from '../components/ads/JuicyAdsComponent';

// ----------------------------------------------------------------------

export default function LoifPost() {
  const [DEFAULT_IMAGES_SHOWN, setDefaultImagesShown] = useState(29);

  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [nameId, setNameId] = useState('')
  const [isLoading, setLoading] = useState(true);
  const [imagesShowing, setImagesShowing] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [dateModified, setDateModified] = useState('Unknown');

  const { id } = useParams();

  

  useEffect(() => {

    const isVideo = (url) => url.endsWith('.mp4');

    const responseSuccessAction = (result) => {
      setImages(result.filter(item => !isVideo(item)));
      setVideos(result.filter(item => isVideo(item)));

      if (result.length > 300) {
        setDefaultImagesShown(100);
      }
    }

    const searchParams = new URLSearchParams(window.location.search);

    if (id === undefined || id === null || id === '' || id === 'index.html') {
      window.location.href = '/';
    }

    setNameId(id);
    const date = searchParams.get('date');
    setDateModified(date);

    setLoading(true);
    
    // The date is used to drives the cache. If there's a new date in the url, it will be forced to re-fetch
    const url = GET_MODEL_IMAGES_URL(id, date);

    getCachedData(url).then(
      (items) => {
        if (items) {
          responseSuccessAction(items);

          console.debug(`From cache ${url}`);
          console.debug(items);
          setLoading(false);
        } else {
          fetch(url)
          .then(res => res.json())
          .then(
            (result) => {
              if (result !== undefined) {
                responseSuccessAction(result);
                putCacheData(url, result);
              }
              console.debug(result);
              setLoading(false);
            },
            (error) => {
              console.error(error);
              setLoading(false);
            }
          );
        }
      }
    )
  }, [id]);


  const handleNext = () => {

    const calc = imagesShowing + DEFAULT_IMAGES_SHOWN;
    if (calc < images.length) {
      setImagesShowing(calc);
      setCurrentPage(currentPage + 1);
      window.scrollTo(0, 0);
    }
  }

  const handlePrevious = () => {
    const calc = imagesShowing - DEFAULT_IMAGES_SHOWN;
    if (calc >= 0) {
      setImagesShowing(calc);
      setCurrentPage(currentPage - 1)
      window.scrollTo(0, 0);
    }
  }

  return (
    <>
      <Helmet>
        <title> {nameId} collection of beautiful photos videos leaks | Loif  </title>
      </Helmet>

      <Container sx={{ paddingBottom: '20px' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            { (images.length === 0 ) 
              ? `No collections found for: ${nameId}` 
              : `${nameId} (${images.length} Photos & ${videos.length} Videos)` 
            }
          </Typography>
          <Typography variant="caption" gutterBottom>
            Last Updated: { (dateModified === '') ? 'Unknown' : dateModified }
          </Typography>
        </Stack>

        <JuicyAdsComponent />

        <Grid container spacing={3}>
          <JuicyAdsComponent adType="square" />

          {images.slice(imagesShowing, Math.min(imagesShowing + DEFAULT_IMAGES_SHOWN, images.length))
            .map((imageUrl, index) => (
              <BlogPostCard 
                key={index} 
                post={{
                  folder_name: nameId,
                  last_modified_date: dateModified,
                }} 
                index={index}
                cover={imageUrl}
                isPost />
            ))
          }
        </Grid>

{/* Pagination */}
        <Stack direction="row" alignItems="center" justifyContent="center" spacing={3} m={3}>
          <Button variant="outlined" startIcon={<NavigateBeforeIcon size="large"/>} onClick={handlePrevious} />
          <Typography variant="h6"> { currentPage } / { Math.ceil(images.length / DEFAULT_IMAGES_SHOWN) } </Typography>
          <Button variant="outlined" endIcon={<NavigateNextIcon size="large"/>} onClick={handleNext} />
        </Stack>

        <Grid container spacing={3} mt={10}>
          <JuicyAdsComponent adType="square" />

          {videos.map((videoUrl, index) => (
              <BlogPostCard
                key={index}
                post={{
                  folder_name: nameId,
                  last_modified_date: dateModified,
                }}
                index={index}
                cover={videoUrl}
                isPost />
            ))
          }
        </Grid>
      </Container>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
