import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Typography } from '@mui/material';
// components
// import Logo from '../../components/logo';

// ----------------------------------------------------------------------

const StyledHeader = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  // position: 'absolute',
  padding: theme.spacing(2, 2, 2),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(3, 3, 3),
  },
}));

// ----------------------------------------------------------------------

export default function SimpleLayout() {
  return (
    <>
      <StyledHeader>
        {/* <Logo /> */}
          <Typography variant="h2" sx={{ fontFamily: 'Roboto', fontStyle: 'italic', fontWeight: 100 }}>
            <Link href='/' underline='none' color='black'>
              Lòi Collections
            </Link>
          </Typography>
      </StyledHeader>

      <Outlet />
    </>
  );
}
