import { Navigate, useRoutes } from 'react-router-dom';
// layouts
// import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import LoifPage from './pages/LoifPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import LoifPost from './pages/LoifPost';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { path: '/', element: <LoifPage />, index: true},
        { path: '/index.html', element: <Navigate to="/" /> },
        { path: '/:id', element: <LoifPost /> },  // Parametized posts 
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/" /> },
      ],
    }
  ]);

  return routes;
}
